import React from "react";
import "./style.css";

export const HomepageDesktop = () => {
  return (
    <div className="homepage-desktop">
      <div className="div">
        <img className="layer" alt="Layer" src="/img/layer-1.svg" />
        <div className="overlap">
          <img className="img" alt="Layer" src="/img/layer-1-1.svg" />
          <div className="group">
            <img className="vector" alt="Vector" src="/img/vector-73.svg" />
          </div>
        </div>
        <div className="overlap-group">
          <div className="overlap-2">
            <div className="text-wrapper">Build your widget tools</div>
            <div className="rectangle" />
            <p className="p">Build the layout of your interview rooms</p>
            <p className="text-wrapper-2">
              Select various widget tools to use when face-timing your candidate, and in whichever layout you desire.
            </p>
            <img className="element" alt="Element" src="/img/1.svg" />
            <div className="overlap-wrapper">
              <div className="overlap-3">
                <img className="layer-2" alt="Layer" src="/img/layer-1-6.svg" />
                <div className="group-wrapper">
                  <img className="group-2" alt="Group" src="/img/group-28.png" />
                </div>
              </div>
            </div>
          </div>
          <p className="design-custom">
            Design custom questions, coding exercises, notes, upload your PDF&#39;s, enable text-to-speech, and more.
          </p>
          <div className="overlap-4">
            <div className="layer-3">
              <div className="overlap-5">
                <div className="img-wrapper">
                  <img className="group-3" alt="Group" src="/img/group-1.png" />
                </div>
                <div className="overlap-6">
                  <img className="vector-2" alt="Vector" src="/img/vector-68.svg" />
                  <img className="vector-3" alt="Vector" src="/img/vector-69.svg" />
                </div>
                <div className="vector-wrapper">
                  <img className="vector-4" alt="Vector" src="/img/vector-72.svg" />
                </div>
                <div className="overlap-group-2">
                  <img className="vector-5" alt="Vector" src="/img/vector-70.svg" />
                  <img className="group-4" alt="Group" src="/img/group-2.png" />
                  <img className="group-5" alt="Group" src="/img/group-3.png" />
                  <img className="group-6" alt="Group" src="/img/group-4.png" />
                  <img className="vector-6" alt="Vector" src="/img/vector-71.svg" />
                </div>
              </div>
              <img className="group-7" alt="Group" src="/img/group.png" />
            </div>
            <div className="div-wrapper">
              <div className="group-8">
                <div className="rectangle-2" />
                <div className="rectangle-3" />
                <div className="rectangle-4" />
                <div className="rectangle-5" />
              </div>
            </div>
          </div>
        </div>
        <div className="overlap-7">
          <div className="overlap-8">
            <img className="vector-7" alt="Vector" src="/img/vector.svg" />
            <img className="vector-8" alt="Vector" src="/img/vector-5.svg" />
            <div className="navbar">
              <div className="text-wrapper-3">Home</div>
              <div className="text-wrapper-4">About</div>
              <div className="text-wrapper-5">Integration</div>
              <div className="text-wrapper-6">Pricing</div>
            </div>
            <div className="transform-online">
              Transform Online
              <br />
              Interviews
            </div>
            <div className="frame">
              <div className="text-wrapper-7">Our product</div>
            </div>
            <div className="frame-2">
              <div className="text-wrapper-8">
               <a href="https://app.interviewr.net/" style={{ color: '#FFF' }} target="_blank">Launch App</a> 
              </div>
            </div>
            <div className="group-9">
              <div className="rectangle-6" />
              <div className="rectangle-7" />
              <img className="group-10" alt="Group" src="/img/group-22.png" />
            </div>
            <div className="group-11">
              <div className="rectangle-8" />
              <div className="rectangle-9" />
              <div className="rectangle-10" />
              <div className="rectangle-11" />
              <img className="group-12" alt="Group" src="/img/group-23.png" />
            </div>
            <div className="ellipse" />
            <img className="vector-9" alt="Vector" src="/img/vector-52.svg" />
            <img className="image" alt="Image" src="/img/image-3.png" />
            <img className="layer-4" alt="Layer" src="/img/layer-1-2.svg" />
            <img className="isolation-mode" alt="Isolation mode" src="/img/isolation-mode.svg" />
          </div>
          <img className="interviewr-logo" alt="Interviewr logo" src="/img/interviewr-logo-white-bg-1.png" />
          <div className="frame-3">
            <div className="text-wrapper-8">Learn more</div>
          </div>
          <p className="text-wrapper-9">
            Welcome to a transformative interview experience, where our platform enables effortless capture and analysis
            of candidate information through video, speech-to-text, shared coding environments, and more!
          </p>
        </div>
        <div className="overlap-9">
          <img className="vector-10" alt="Vector" src="/img/vector-1.svg" />
          <img className="vector-11" alt="Vector" src="/img/vector-2.svg" />
          <img className="rectangle-12" alt="Rectangle" src="/img/rectangle-19.png" />
          <img className="vector-12" alt="Vector" src="/img/vector-48.svg" />
        </div>
        <div className="overlap-10">
          <img className="vector-13" alt="Vector" src="/img/vector-3.svg" />
          <img className="vector-14" alt="Vector" src="/img/vector-4.svg" />
          <img className="group-13" alt="Group" src="/img/group-13.png" />
          <img className="group-14" alt="Group" src="/img/group-14.png" />
          <div className="rectangle-13" />
          <p className="text-wrapper-10">
            Conducting technical interviews with real-time coding and syntax highlighting has never been smoother! This
            platform offers an intuitive interface that simplifies the evaluation process, allowing us to focus on
            assessing skills effectively
          </p>
          <div className="text-wrapper-11">Michael L.</div>
          <div className="text-wrapper-12">HR Director</div>
          <div className="text-wrapper-13">What Our Clients Say</div>
          <div className="frame-4">
            <div className="text-wrapper-7">Testimonials</div>
          </div>
          <img className="ellipse-2" alt="Ellipse" src="/img/ellipse-1.png" />
          <img className="vector-15" alt="Vector" src="/img/vector-6.svg" />
          <div className="rectangle-14" />
          <p className="this-platform">
            This platform revolutionized our interview process! The ability to seamlessly switch between video, code
            evaluation, and resume review in real-time has significantly enhanced our candidate assessment. It&#39;s a
            game-changer!
          </p>
          <div className="text-wrapper-14">Sandra M.</div>
          <p className="text-wrapper-15">Talent Acquisition Specialist, UL-UX Studio</p>
          <img className="ellipse-3" alt="Ellipse" src="/img/ellipse-2.png" />
          <img className="group-15" alt="Group" src="/img/group-4-1.png" />
          <img className="vector-16" alt="Vector" src="/img/vector-7.svg" />
          <div className="rectangle-15" />
          <p className="text-wrapper-16">
            Finally, an interview platform that caters to both candidates and interviewers! The coding evaluation in
            real-time, coupled with video chat, creates an interactive and insightful experience. Impressive!
          </p>
          <div className="text-wrapper-17">Daniel P.</div>
          <div className="text-wrapper-18">General Manager, MMSA</div>
          <img className="ellipse-4" alt="Ellipse" src="/img/ellipse-3.png" />
          <img className="vector-17" alt="Vector" src="/img/vector-6.svg" />
          <div className="group-16">
            <img className="vector-18" alt="Vector" src="/img/vector-60.svg" />
          </div>
          <div className="group-17">
            <img className="vector-19" alt="Vector" src="/img/vector-61.svg" />
          </div>
          <img className="vector-20" alt="Vector" src="/img/vector-45.svg" />
          <img className="vector-21" alt="Vector" src="/img/vector-46.svg" />
          <img className="vector-22" alt="Vector" src="/img/vector-39.svg" />
          <div className="ellipse-5" />
        </div>
        <p className="text-wrapper-19">Navigate to your interview room with a unique URL</p>
        <div className="text-wrapper-20">Review the entire interview</div>
        <div className="text-wrapper-21">Manage candidates</div>
        <p className="text-wrapper-22">
          Simply copy and paste a unique room URL for you and your candidate to interview in. Enjoy all the digital
          tools you previously set up during the interview.
        </p>
        <p className="look-back-at-your">
          Look back at your interview in all its glory - notes you&#39;ve taken, questions answered, speech transcript,
          and more.
        </p>
        <p className="text-wrapper-23">
          You can take notes for each candidate, leave comments, see past interviews, and more.
        </p>
        <p className="elevating-dialogues">
          <span className="span">Elevating Dialogues</span>
          <span className="text-wrapper-24">
            {" "}
            <br />
            Unlocking Potential
          </span>
        </p>
        <div className="frame-5">
          <div className="text-wrapper-7">About us</div>
        </div>
        <p className="join-us-at">
          Join us at Interviewr, where interviews evolve into captivating conversations. With an arsenal of cutting-edge
          tools and an unwavering dedication to progress, we&#39;re passionately committed to unlocking the boundless
          potential within every interaction, reshaping the interview landscape.
        </p>
        <p className="interview-room">
          <span className="span">Interview room</span>
          <span className="text-wrapper-24">
            {" "}
            <br />
            Dynamic Interaction
          </span>
        </p>
        <div className="frame-6">
          <div className="text-wrapper-7">Product Features</div>
        </div>
        <p className="text-wrapper-25">
          Welcome to our Next-Gen Interview Space, a dynamic arena designed for live interviews and candidate
          assessments, all accessible through a unique, user-friendly URL. Here, you can engage in live video
          conversations, enable speech-to-text translation, collaborate on code exercises, and effortlessly employ
          pre-set widgets, ensuring a comprehensive and insightful evaluation experience for every interview session.
        </p>
        <p className="candidate-management">
          <span className="span">Candidate Management</span>
          <span className="text-wrapper-24">
            {" "}
            <br />
            Profile Control
          </span>
        </p>
        <div className="frame-7">
          <div className="text-wrapper-7">Product Features</div>
        </div>
        <p className="text-wrapper-26">
          Explore our Holistic Candidate Management Suite for comprehensive control. Annotate resumes and cover letters,
          leave personalized or shared notes, manage contact information, and seamlessly track interview progress across
          all candidates in one intuitive interface
        </p>
        <p className="room-template">
          <span className="span">
            Room template
            <br />
          </span>
          <span className="text-wrapper-24">Personalized Interview</span>
        </p>
        <div className="frame-8">
          <div className="text-wrapper-7">Product Features</div>
        </div>
        <p className="build-personalized">
          Build Personalized Interview Room Blueprints using our template feature. Customize layouts, incorporate
          diverse widget tools—such as coding challenges, resume viewing, and interactive Q&amp;A—and effortlessly save
          these configurations for recurring job roles.
        </p>
        <p className="account-management">
          <span className="span">
            Account Management
            <br />
          </span>
          <span className="text-wrapper-24">Control Center</span>
        </p>
        <div className="frame-9">
          <div className="text-wrapper-7">Product Features</div>
        </div>
        <p className="text-wrapper-27">
          Oversee user accounts, access permissions, and billing intricacies seamlessly through our Platform Oversight
          feature, simplifying administrative tasks.
        </p>
        <div className="overlap-11">
          <img className="vector-23" alt="Vector" src="/img/vector-9.svg" />
          <img className="group-18" alt="Group" src="/img/group-10.png" />
          <img className="vector-24" alt="Vector" src="/img/vector-10.svg" />
          <img className="vector-25" alt="Vector" src="/img/vector-15.svg" />
          <img className="screens" alt="Screens" src="/img/screens-02-1.png" />
          <img className="vector-26" alt="Vector" src="/img/vector-51.svg" />
          <img className="vector-27" alt="Vector" src="/img/vector-55.svg" />
          <img className="frame-10" alt="Frame" src="/img/frame.svg" />
        </div>
        <div className="overlap-12">
          <img className="group-19" alt="Group" src="/img/group-15.png" />
          <img className="vector-28" alt="Vector" src="/img/vector-11.svg" />
          <img className="vector-29" alt="Vector" src="/img/vector-12.svg" />
          <img className="screens-2" alt="Screens" src="/img/screens-03-1.png" />
          <img className="layer-5" alt="Layer" src="/img/layer-1-3.svg" />
          <img className="vector-30" alt="Vector" src="/img/vector-55.svg" />
          <img className="frame-11" alt="Frame" src="/img/frame-1.svg" />
        </div>
        <div className="overlap-13">
          <img className="vector-31" alt="Vector" src="/img/vector-13.svg" />
          <img className="vector-32" alt="Vector" src="/img/vector-14.svg" />
          <img className="layer-6" alt="Layer" src="/img/layer-1-4.svg" />
          <img className="screens-3" alt="Screens" src="/img/screens-04-1.png" />
          <img className="vector-33" alt="Vector" src="/img/vector-54.svg" />
          <img className="vector-34" alt="Vector" src="/img/vector-57.svg" />
          <img className="frame-12" alt="Frame" src="/img/frame-2.svg" />
        </div>
        <div className="overlap-14">
          <img className="vector-35" alt="Vector" src="/img/vector-18.svg" />
          <div className="group-20">
            <p className="text-wrapper-28">
              Stay informed and inspired! Subscribe to our newsletter for the latest updates, exclusive content, and
              insights delivered to your inbox.
            </p>
            <div className="text-wrapper-29">Subscribe to our newsletter</div>
            <div className="overlap-group-wrapper">
              <div className="overlap-group-3">
                <div className="rectangle-16" />
                <div className="rectangle-17" />
                <div className="text-wrapper-30">Subscribe</div>
                <p className="text-wrapper-31">Enter a valid email address</p>
              </div>
            </div>
            <p className="our-privacy-policy">
              <span className="text-wrapper-32">Our </span>
              <span className="text-wrapper-33">Privacy Policy</span>
              <span className="text-wrapper-32"> has more information about how we use your data.</span>
            </p>
          </div>
          <div className="text-wrapper-34">Interviewr</div>
          <div className="text-wrapper-35">Product</div>
          <div className="text-wrapper-36">Interviewr Inc. 2021</div>
          <div className="text-wrapper-37">Company</div>
          <p className="element-interviewr">
            <span className="text-wrapper-38">
              © 2023 Interviewr&nbsp;&nbsp; |&nbsp;&nbsp; Terms and Conditions&nbsp;&nbsp; |&nbsp;&nbsp; All rights
              reserved
            </span>
          </p>
          <div className="text-wrapper-39">More info</div>
          <div className="text-wrapper-40">Resources</div>
          <div className="yearly-monthly">
            Yearly
            <br />
            Monthly
          </div>
          <div className="text-wrapper-41">Pricing</div>
          <div className="email-ian-interviewr">
            Email: support@interviewr.net
            <br />
            Phone: 604-720-8305
          </div>
          <div className="text-wrapper-42">Contact Us</div>
          <img className="interviewr-logo-dark" alt="Interviewr logo dark" src="/img/interviewr-logo-dark-bg-1.png" />
          <div className="ellipse-6" />
          <div className="ellipse-7" />
          <div className="ellipse-8" />
          <img className="vector-36" alt="Vector" src="/img/vector-42.svg" />
          <img className="vector-37" alt="Vector" src="/img/vector-43.svg" />
          <img className="vector-38" alt="Vector" src="/img/vector-44.svg" />
        </div>
        <div className="overlap-15">
          <img className="vector-39" alt="Vector" src="/img/vector-17.svg" />
          <img className="vector-40" alt="Vector" src="/img/vector-1-1.svg" />
          <img className="group-21" alt="Group" src="/img/group-10.png" />
          <p className="text-wrapper-43">Choose your best pricing plan</p>
          <div className="frame-13">
            <div className="text-wrapper-7">Pricing</div>
          </div>
          <p className="text-wrapper-44">
            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
          </p>
          <div className="rectangle-18" />
          <div className="rectangle-19" />
          <div className="text-wrapper-45">Yearly</div>
          <div className="text-wrapper-46">1628</div>
          <div className="text-wrapper-47">$</div>
          <div className="frame-14">
            <div className="text-wrapper-8">Subscribe</div>
          </div>
          <img className="diamond" alt="Diamond" src="/img/diamond-1.png" />
          <div className="text-wrapper-48">Live video chat</div>
          <img className="vector-41" alt="Vector" src="/img/vector-19.svg" />
          <p className="custom-widget-tools">Custom widget tools &amp; templates</p>
          <img className="vector-42" alt="Vector" src="/img/vector-19.svg" />
          <div className="text-wrapper-49">Speech-to-text translation</div>
          <img className="vector-43" alt="Vector" src="/img/vector-19.svg" />
          <p className="text-wrapper-50">Live code sharing, PDF highlights, etc.</p>
          <img className="vector-44" alt="Vector" src="/img/vector-19.svg" />
          <div className="text-wrapper-51">Custom made questionnaires</div>
          <img className="vector-45" alt="Vector" src="/img/vector-19.svg" />
          <div className="text-wrapper-52">Candidate management and more</div>
          <img className="vector-46" alt="Vector" src="/img/vector-19.svg" />
          <div className="rectangle-20" />
          <div className="rectangle-21" />
          <div className="text-wrapper-53">Monthly</div>
          <div className="text-wrapper-54">148</div>
          <div className="text-wrapper-55">$</div>
          <div className="frame-15">
            <div className="text-wrapper-8">Subscribe</div>
          </div>
          <img className="diamond-2" alt="Diamond" src="/img/diamond-2.png" />
          <div className="text-wrapper-56">Live video chat</div>
          <img className="vector-47" alt="Vector" src="/img/vector-25.svg" />
          <p className="custom-widget-tools-2">Custom widget tools &amp; templates</p>
          <img className="vector-48" alt="Vector" src="/img/vector-25.svg" />
          <div className="text-wrapper-57">Speech-to-text translation</div>
          <img className="vector-49" alt="Vector" src="/img/vector-25.svg" />
          <p className="text-wrapper-58">Live code sharing, PDF highlights, etc.</p>
          <img className="vector-50" alt="Vector" src="/img/vector-25.svg" />
          <div className="text-wrapper-59">Custom made questionnaires</div>
          <img className="vector-51" alt="Vector" src="/img/vector-25.svg" />
          <div className="text-wrapper-60">Candidate management and more</div>
          <img className="vector-52" alt="Vector" src="/img/vector-25.svg" />
          <div className="rectangle-22" />
          <div className="rectangle-23" />
          <div className="text-wrapper-61">Free Trial</div>
          <div className="text-wrapper-62">0</div>
          <div className="text-wrapper-63">$</div>
          <div className="frame-16">
            <div className="text-wrapper-8">Subscribe</div>
          </div>
          <div className="text-wrapper-64">--</div>
          <img className="vector-53" alt="Vector" src="/img/vector-31.svg" />
          <div className="text-wrapper-65">--</div>
          <img className="vector-54" alt="Vector" src="/img/vector-31.svg" />
          <div className="text-wrapper-66">--</div>
          <img className="vector-55" alt="Vector" src="/img/vector-31.svg" />
          <div className="text-wrapper-67">--</div>
          <img className="vector-56" alt="Vector" src="/img/vector-31.svg" />
          <div className="text-wrapper-68">--</div>
          <img className="vector-57" alt="Vector" src="/img/vector-31.svg" />
          <div className="text-wrapper-69">--</div>
          <img className="vector-58" alt="Vector" src="/img/vector-31.svg" />
          <img className="kite" alt="Kite" src="/img/kite-1.png" />
          <img className="vector-59" alt="Vector" src="/img/vector-37.svg" />
          <img className="vector-60" alt="Vector" src="/img/vector-38.svg" />
          <img className="vector-61" alt="Vector" src="/img/vector-39.svg" />
          <div className="ellipse-9" />
        </div>
        <img className="vector-62" alt="Vector" src="/img/vector-40.png" />
        <img className="vector-63" alt="Vector" src="/img/vector-40.png" />
        <div className="overlap-16">
          <div className="overlap-17">
            <img className="vector-64" alt="Vector" src="/img/vector-49.svg" />
            <img className="vector-65" alt="Vector" src="/img/vector-50.svg" />
            <img className="vector-66" alt="Vector" src="/img/vector-51.svg" />
            <img className="group-22" alt="Group" src="/img/group-15.png" />
            <img className="layer-7" alt="Layer" src="/img/layer-1-5.svg" />
            <img className="group-23" alt="Group" src="/img/group-26.png" />
            <div className="rectangle-24" />
            <div className="rectangle-25" />
            <img className="group-24" alt="Group" src="/img/group-27.png" />
            <img className="group-25" alt="Group" src="/img/group-5.png" />
            <img className="vector-67" alt="Vector" src="/img/vector-59.svg" />
            <img className="frame-17" alt="Frame" src="/img/frame-3.svg" />
          </div>
          <img className="vector-68" alt="Vector" src="/img/vector-58.svg" />
        </div>
        <div className="group-26">
          <div className="overlap-18">
            <img className="layer-8" alt="Layer" src="/img/layer-1-7.svg" />
            <div className="icon-wrapper">
              <img className="icon" alt="Icon" src="/img/icon.png" />
            </div>
          </div>
        </div>
        <div className="group-27">
          <div className="overlap-18">
            <img className="layer-9" alt="Layer" src="/img/layer-1-8.svg" />
            <div className="group-28">
              <img className="group-2" alt="Group" src="/img/group-30.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
